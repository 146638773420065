/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <SiteHeader />

        <Column className="mt--30 pb--0 pt--0" name={"referenzen"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Referenzen"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Text className="text-box text-box--left text-box--invert pl--06" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"referenzen-2"}>
          
          <ColumnWrap className="column__flex --left el--3 pb--06 pt--06" columns={"3"}>
            
            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/121/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/121/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/121/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/121/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/121/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/121/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":420}} content={"Büroreinigung <br> Jaraskova 1234/5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":420}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--50 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left" content={"Haben sie interesse an der professionellen Reinigung?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--right" href={"/de/kontakt"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Professionelle Reinigungsfirma Olomouc"}>
              </Title>

              <Text className="text-box text-box--invert" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}